import { Grid, Typography } from "@mui/material";
import React from "react";
import CgIcon from '../../src/images/cg-icon.png';

const AppHeader = () => (
    <Grid
        item
        container
        xs={12}
        md={12}
        lg={12}
        style={{
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            padding: '20px',
            color: 'white',
            marginBottom: '50px'
        }}
    >
        <Grid item>
            <a href='/'>
                <img src={CgIcon} width='40' height='40' />
            </a>
        </Grid>
        <Grid item>
            <Typography variant='h2' style={{ marginLeft: '12px' }}>
                CampaignGuru
            </Typography>
        </Grid>
    </Grid>
);

export default AppHeader;
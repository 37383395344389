import createAxiosInstance from "./utils/axiosInstance";
import moment from 'moment';

// export const filterDistinctObjects = (arr, existingArrayOfObjects) => {
//   const seen = new Set();
//   const existingKeys = new Set(existingArrayOfObjects?.map(item => `${item.name}`));
//   return arr.filter(item => {
//     const k = `${item.name}|${item.value}`;
//     if (existingKeys.has(item.name)) {
//       return false;
//     }
//     return seen.has(k) ? false : seen.add(k);
//   });
// };
export const filterDistinctObjects = (arr, selectedTags = []) => {
  const seen = new Set();
  const existingKeys = new Set(selectedTags?.map(item => item.name));
  return arr.filter(item => {
    const k = `${item.name}|${item.value}`;
    if (existingKeys.has(item.name)) {
      return false;
    }
    return seen.has(k) ? false : seen.add(k);
  });
};

export const fetchProgressData = async (orgId,target,segmentId,start_date,due_date) => {
  try {
    const userActionResponse = await createAxiosInstance().post('/api/find-count-in-segment',{
      data:{
        orgId,
        segmentId,
        start_date,
        due_date
      }
    } ,{
      params: {
        orgId,
      },
    });
    const progress = userActionResponse.data.data;
    const progressPercentage = (parseInt(progress,10) / parseInt(target, 10)) * 100;
    return {
      progress,
      progressPercentage: Math.round(progressPercentage),
    };
  } catch (error) {
    console.error("Error fetching progress data:", error);
    return {
      progress: 0,
      progressPercentage: 0,
    };
  }
};

export const getTagSuggestions = async (setSuggestions, searchTerm, orgId, setLoading) => {
  setLoading(true);
  const sectionSlug = `org-${orgId}-tags`; // slug of the tags-section entry for raw tags in the org.
  const searchResp = await createAxiosInstance().get(
    `api/app-tag-sections?filters[slug]=${sectionSlug}`,
    {
      params: {
        orgId,
        populate: {
          organisation: {
            populate: '*'
          },
          tags: {
            filters: {
              tagSectionId: {
                slug: {
                  $eq: sectionSlug
                }
              },
              name: {
                $containsi: searchTerm // Case-insensitive search
              }
            },
            populate: '*'
          },
        }
      }
    }
  );
  const results = searchResp?.data?.data?.[0]?.attributes?.tags?.data;

  setLoading(false);

  if (results?.length) {
    const tags = JSON.parse(JSON.stringify(results));
    const mappedTags = tags?.map(item => ({ id: item?.id, name: item?.attributes?.name, value: item?.attributes?.name, slug: item?.attributes?.tagSectionId?.data?.attributes?.slug, count: item?.attributes?.count }))
    setSuggestions(mappedTags);
  } else {
    setSuggestions([]);
  }
};

export const getAddressFromContact = (contact) => {
  const { street_address, streetname, city, state, zip } = contact;
  return [ street_address, streetname, city, state, zip ].filter(name => name)?.join(', ');
};

export const getAge = (contact) => {
  let age = "";
  if(contact?.date_of_birth){
    const birthDate = moment(contact?.date_of_birth);
    const currentDate = moment();
    age = currentDate.diff(birthDate, 'years');
  } else if (contact?.year_of_birth) {
    const currentYear = new Date().getFullYear();
    age = currentYear - parseInt(contact?.year_of_birth);
  }
  return age;
}

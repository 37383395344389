import React, { lazy, useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Link,
    Routes,
    useLocation
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthRoutes from './AuthRoutes';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Snackbar, Grid } from '@mui/material';

import createAxiosInstance from "./utils/axiosInstance";
import { setUser } from "./redux/slices/userSlice";
import { setToast, toastInfo } from "./redux/slices/toastSlice";
import LandingPage from "./landing/LandingPage.js";
const LoginPage = lazy(() => import("./login/LoginPage"));
const ConfirmNewUser = lazy(() => import("./login/ConfirmNewUser"));
const AcceptInvite = lazy(() => import("./teams/AcceptInvite"));
const ForgotPassword = lazy(() => import("./login/ForgotPassword"));
const ResetPassword = lazy(() => import("./login/ResetPassword"));
const ThankYou = lazy(() => import('./survey/ThankYou.js'));
const PrivacyPolicy = lazy(() => import('./common/PrivacyPolicy'));

const RouteWrapper = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state?.user?.user);
    const appToast = useSelector(toastInfo);

    const handleCloseAppToast = () => {
        dispatch(setToast({type: '', open: false, message: ''}));
    }
    const token = localStorage.getItem('token');
    const fetchAuthUserInfo = async () => {
        try {
            const resp = await createAxiosInstance().get('/api/users/me');
            // console.log("auth user fetched:::", resp?.data, "location:::", location);
            if (resp?.data?.id) {
                dispatch(setUser(resp?.data));
                // setIsAuthenticated(true);
            } else {
                // setIsAuthenticated(false);
                localStorage.removeItem('token');
            }
        } catch (error) {
            console.error("Error fetching user info:", error);
            // setIsAuthenticated(false);
            localStorage.removeItem('token');
        }
    }

    useEffect(() => {
        if (token && !userInfo?.id) {
            fetchAuthUserInfo();
        }
    }, [userInfo]);

    const path = window.location.pathname;
    const allowedPaths = [
        '/',
        '/login',
        '/forgot-password',
        '/reset-password',
        '/register',
        '/confirm-new-user/:token',
        '/accept-team-invite/:token',
        '/verify-user-token',
        '/org/:orgId/survey/:surveyId',
        'thank-you',
        '/privacy-policy'
    ];
    const regexPaths = [/\/accept-team-invite\/.+/, /\/confirm-new-user\/.+/];
    if (
        !token
        && !allowedPaths.includes(path)
        && !regexPaths.some(regex => regex.test(path))
    ) {
        window.location.href = '/login';
    }
  
    return (
        <Router>
            <Grid item xs={12} md={12} lg={12}>
                <Snackbar
                    open={appToast?.open}
                    autoHideDuration={5000}
                    onClose={handleCloseAppToast}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={handleCloseAppToast}
                        severity={appToast?.type}
                        sx={{ width: '100%', '& .MuiAlert-action': { display: `${appToast?.open ? "" : "none"}` } }}
                    >
                        {appToast?.message}
                    </Alert>
                </Snackbar>
            </Grid>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/verify-user-token" element={<ConfirmNewUser />} />
                <Route path="/accept-team-invite/:token" element={<AcceptInvite />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/thank-you" element={<ThankYou />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                {/* Private routes */}
                <Route path="/*" element={token && userInfo?.id && <AuthRoutes /> } />
            </Routes>
        </Router>
    );
};

export default RouteWrapper;